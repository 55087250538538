import React, { memo } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { THEMING } from '/src/app/consts'
import { Logo } from '/src/storybook/components/Logo'
import './Header.scss'


const Header = memo(({ user }) => {
  const history = useHistory()
  const isHV = _.startsWith(window.location.hostname, 'hv.')
  let title = <>Plan your <span className="colored"> Weka.</span></>
  let text = <>
    <p>This Sizer is designed as a conversation starter with high-level performance and pricing</p>
    <p>numbers for the different reference architecture.</p>
    <p>Please <span className="emphasized">Don't</span> share the screen with customers/partners</p>
    <p>
      If you need help you may want to visit
      <span className="emphasized documentation">
        <a target="blank" href="https://docs.weka.io/"> Weka Documentation.</a>
      </span>
    </p>
    <p>Weka continually tests and characterizes performance using industry standard</p>
    <p>benchmarks such as fio and mdtest on various hardware configurations. The</p>
    <p>performance numbers indicated below are Weka's expectations for real world aggregate</p>
    <p>performance a user could attain for the workload mix specified in the PERFORMANCE</p>
    <p>section in the left column. Actual results may vary. Please contact your local Weka sales</p>
    <p>team or sales@weka.io with further questions.</p>
  </>
  if (isHV) {
    title = THEMING.HV.title
    text = <>
      {_.map(THEMING.HV.text, line => (<p>{line}</p>))}
    </>
  }
  return (
    <div className="header">
      <div className="top-line">
        <Logo isHV={isHV}/>
        <a target="blank" className="documentation-link" href="https://docs.weka.io/">Open Documentation</a>
      </div>
      <div className="centered">
        <div className="welcome-message">
          Hello {_.get(user, 'name')}!
          <a onClick={() => history.push("/logout")}> (logout)</a>
        </div>
        <div className="title">{title}</div>
        <div className="sub-title">THE FILE SYSTEM FOR THOSE WHO SOLVE BIG PROBLEMS.</div>
        {text}
      </div>
    </div>
  )
})

export default Header