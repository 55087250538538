import _ from 'lodash'
import React, { memo } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import classNames from 'classnames'
import config from '/src/config'
import { Button } from '/src/storybook/components/Button'
import { Footer } from '/src/storybook/components/Footer'
import { Input } from '/src/storybook/components/Input'
import { Logo } from '/src/storybook/components/Logo'
import { ExpiredToken } from './ExpiredToken'
import './PartnerLogin.scss'


const PartnerLoginView = memo(({ emaildomain, partnername, userEmail, setUserEmail, isExpiredToken,
    clickHandler, tokenAPIPayload }) => {
  const hostname = window.location.hostname
  const isHV = _.startsWith(hostname, 'hv.')
  // const isChannel = _.startsWith(hostname, 'channel.')
  const isChannel = false
  const { loginWithRedirect } = useAuth0()

  const login = () => {
    config.AUTH_CLIENT.signInWithRedirect()
  }

  return (
    <>
      <div className="partner-login">
        <div className="header">
          <Logo isWhite isHV={isHV}/>
          <div className="container">
            <div className="title">
              Plan Your <span>Weka</span> with {partnername}
            </div>
            <div className="sub-title">
              THE FILE SYSTEM FOR THOSE WHO SOLVE BIG PROBLEMS.
            </div>
            <div className="text">
              The Sizer is designed as a conversation starter with high-level performance numbers for the different reference architecture.
            </div>
            <div className="text">
              <span>If you need help you may want to visit </span>
              <span className="documentation">
                <a target="blank" href="https://docs.weka.io/">Weka Documentation.</a>
              </span>
            </div>
            <div className="login-options">
              { !isExpiredToken &&
                <>
                  <div className="box">
                    <div className="sign-in-text">
                      Sign in to Sizer
                    </div>
                    <div className="work-for">
                      I work for <span>Weka</span>
                    </div>
                    <Button okta onClick={login} label="Sign in with Okta" />
                  </div>
                  <div className="box">
                    <div className="sign-in-text">
                      Sign in to Sizer
                    </div>
                    { !isChannel && (
                      <>
                        { emaildomain && (
                          <div className="work-for">
                            I work for <span>{partnername}</span>
                          </div>
                        )}
                        <div className={classNames("my-email", {"no-domain": !emaildomain})}>
                          <i>My email is: </i>
                          <Input value={userEmail} onChange={(e) => setUserEmail(e)}/>
                          { emaildomain && ( <b> @{emaildomain}</b> )}
                        </div>
                        <Button className={classNames({"no-domain": !emaildomain})} onClick={clickHandler} black label="Email me an access link"/>
                      </>
                    )}
                    { isChannel && (
                      <Button className="vartopia-login" onClick={() => loginWithRedirect({
                        appState: { targetUrl: window.location.origin },
                        authorizationParams: {
                          connection: 'SAML-prod',
                          redirect_uri: window.location.origin,
                          scope: "profile email read:current_user create:current_user_metadata"
                        }
                      })} label="Log In with Vartopia"/>
                    )}
                  </div>
                </>
              }
              { isExpiredToken && <ExpiredToken tokenAPIPayload={tokenAPIPayload} /> }
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
})

export default PartnerLoginView
