import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Route, Redirect } from 'react-router'
import { useAuth0 } from "@auth0/auth0-react"
import config from '/src/config'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import { PartnerLogin } from '/src/routes/Login/PartnerLogin'
import { lastLoginTimeSelector, setUserData, setLastLoginTime, userDataSelector } from '/src/routes/Login'
import { handleNotLoggedInUser } from './PrivateRouteServices'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [ pageToRender , setPageToRender] = useState(null)
  const { logout } = useAuth0()
  const wekaUser = useSelector(userDataSelector)
  const lastLoginTime = useSelector(lastLoginTimeSelector)
  const dispatch = useDispatch()
  const { sizerPartnerToken, hiddenRequirements, hiddenOutput, partnerVendors } = wekaUser

  const goToPartnerLogin = (props) => {
    const { data } = props
    setPageToRender(<PartnerLogin {...props} {..._.pick(data, ["hiddenRequirements", "hiddenOutput", "partnerVendors", "whitelist_users"])} />)
  }

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const access_code = urlParams.get('access_code')
    const jsonName = urlParams.get('jsonName')
    const code = urlParams.get('code')
    const hostname = window.location.hostname
    if (config.AUTH_CLIENT.token.isLoginRedirect() && code) {
      let payload = { hostname }
      if (jsonName) {
        payload["jsonName"] = jsonName
      }
      callAPI({
        endpoint: API_ENDPOINTS.GET_ALLOWED_SITES, payload,
        callback: async ( data ) => {
          const { emaildomain, partnerVendors, hiddenRequirements, hiddenOutput } = data
          dispatch(setLastLoginTime(new Date().getTime() / 1000))
          try { // Okta login
            const {tokens} = await config.AUTH_CLIENT.token.parseFromUrl(); // remember to "await" this async call
            config.AUTH_CLIENT.tokenManager.setTokens(tokens);
            await config.AUTH_CLIENT.token.getUserInfo().then((profileObj) => {
              const { name, email } = profileObj
              const visibleVendors = emaildomain ? partnerVendors : _.get(data, ["whitelist_users", email, "vendors"])
              dispatch(setUserData({ name, email, hiddenRequirements, hiddenOutput, partnerVendors: visibleVendors }))
              setPageToRender(<Route {...rest} render={props => (
                <Component {...props} hiddenRequirements={hiddenRequirements} hiddenOutput={hiddenOutput}
                           partnerVendors={partnerVendors}/>)}/>)
            })
          } catch { // Vartopia login
            callAPI({
              endpoint: API_ENDPOINTS.GET_ACCESS_TOKEN,
              payload: {
                grant_type: "authorization_code",
                client_id: config.VARTOPIA_AUTH0_CLIENT_ID,
                client_secret: config.VARTOPIA_AUTH0_CLIENT_SECRET,
                code,
                redirect_uri: window.location.origin,
              },
              callback: async ({access_token}) => {
                const {sub} = await fetch(API_ENDPOINTS.GET_USER_INFO.path, {
                  headers: {Authorization: `Bearer ${access_token}`}
                }).then(res => res.json())
                const email = _.get(sub.split("|"), 2)
                const visibleVendors = emaildomain ? partnerVendors : _.get(data, ["whitelist_users", email, "vendors"])
                dispatch(setUserData({ name: email, email, hiddenRequirements, hiddenOutput, partnerVendors: visibleVendors }))
                window.history.replaceState(null, '', window.location.pathname)
                setPageToRender(<Route {...rest} render={props => (
                  <Component {...props} hiddenRequirements={hiddenRequirements} hiddenOutput={hiddenOutput}
                             partnerVendors={partnerVendors}/>)}/>)
              }
            })
          }
        }
      })
    } else if (_.isEmpty(wekaUser)) { // User not logged in
      handleNotLoggedInUser({
        hostname, jsonName, setPageToRender, sizerPartnerToken, goToPartnerLogin, dispatch, Component, rest, access_code
      })
    } else if (!_.isNil(lastLoginTime) && (new Date().getTime() / 1000 - lastLoginTime) > 3600) {
      console.log("last login problem")
      dispatch(setUserData({}))
      const returnTo = window.location.origin
      // TODO: make sure it's not needed (timeout after Okta login)
      // await logout()
      window.location.href = returnTo
    } else {
      console.log("user logged in")
      setPageToRender(<Route {...rest} render={props => (
        <Component {...props} hiddenRequirements={hiddenRequirements} hiddenOutput={hiddenOutput}
                   partnerVendors={partnerVendors}/>)}/>)
    }
  }, [wekaUser])

  return pageToRender
}

export default PrivateRoute
