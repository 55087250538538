import { OktaAuth } from "@okta/okta-auth-js"

const getMixpanelID = () => {
  if (process.env.ENVIRONMENT === 'prod') {
    return '55cc0aee635d840bb1397150ed482824'
  } else if (process.env.ENVIRONMENT === 'hotfix') {
    return '9077fe39bbf383921fcbd8ef712bbfe4'
  } else if (process.env.ENVIRONMENT === 'react') {
    return '875a4373d597bf3482ae3f11cf408c92'
  } else if (process.env.ENVIRONMENT === 'dev') {
    return '283600d9a6e28e1025927502d585f697'
  }
}

const getVartopiaAuth0ClientID = () => {
  if (process.env.ENVIRONMENT === 'prod') {
    return 'ganbMsSxprXzCqgIFF0w57cL8Wo6x7qw'
  } else if (process.env.ENVIRONMENT === 'hotfix') {
    return '46fYqdBOuvOtaQ6L8duHFc3Jglea8EHH'
  } else if (process.env.ENVIRONMENT === 'react') {
    return 'CSZo6wS3T1bqYs5t4todV8o6owOwFhZ1'
  } else if (process.env.ENVIRONMENT === 'dev') {
    return 'oB10iaGiH47RMpLm9fJsBUUZ5pDNik1S'
  }
}

const getVartopiaAuth0ClientSecret = () => {
  if (process.env.ENVIRONMENT === 'prod') {
    return 'REzHkk2aDYGeTtKHNbl_FTOE0VhY9XZsX3QLjgshGUfMe547licS4jKCY1yhpQqA'
  } else if (process.env.ENVIRONMENT === 'hotfix') {
    return 'wshNn_opwzH7tkAblKgRdqqEMqvdCLJaNgyPJZ3fxDuVJ4vfHj672iXPMioBeh51'
  } else if (process.env.ENVIRONMENT === 'react') {
    return 'FMnx_kBg1tIosbJkuwOnZIcy9KGEdglgXXdPO_Vot52c3Bni0PaZ0KXv36QHh7Il'
  } else if (process.env.ENVIRONMENT === 'dev') {
    return 'mG5iSlFCP-KNva4J_IcH6MmGcrz1yMy3ll-GQpfh-d3b-kxva4ok7vIH28_T5DYg'
  }
}


const config = {
  BACKEND_URL: process.env.ENVIRONMENT ? `https://${process.env.ENVIRONMENT}.agu34eo2ig82e.eu-west-1.cs.amazonlightsail.com/` : 'http://localhost:5001/',
  WEKA_URL: 'https://weka-for-sizer.sizer.weka.io/',
  MIXPANEL_ID: getMixpanelID(),
  AUTH_CLIENT: new OktaAuth({
    issuer: 'https://weka.okta.com',
    clientId: '0oa5fmeac0LDTmITF697',
    redirectUri: window.location.origin + '/',
    responseType: 'code',
    pkce: true
  }),
  VARTOPIA_AUTH0_DOMAIN: "dev-1tvclk615cg7b0qx.us.auth0.com",
  VARTOPIA_AUTH0_CLIENT_ID: getVartopiaAuth0ClientID(),
  VARTOPIA_AUTH0_CLIENT_SECRET: getVartopiaAuth0ClientSecret()
}

export default config
